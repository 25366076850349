<template>
  <div max="h-150" w="160" bg="white" flex="~ col" m="x--5 b--5" rounded="2xl">
    <div v-if="history.length && !isSearching" p="x-5" b-b="1 solid b-t #D9D9D9">
      <div text="base #9D9D9D" font="500">
        {{ t('key.search.destination.history') }}
      </div>
      <div class="flex flex-wrap" p="t-3 b-1">
        <div v-for="item in history" :key="item" class="cursor-pointer truncate" text="sm #646464" p="y-2 x-4" m="r-3 b-3" font="500" bg="#F3F3F3" rounded="10" @click="searchInput = item">
          {{ item }}
        </div>
      </div>
    </div>
    <div v-else m="t--5"></div>
    <div v-if="isSearching" b-5="1 solid b-t #D9D9D9" p="y-4" max-h="100">
      <template v-if="searchAttrList[E_RANK_TYPE.ATTRACTIONS].length || searchAttrList[E_RANK_TYPE.EXPERIENCE].length">
        <div class="max-h-80" overflow="y-auto">
          <div
            v-for="item in searchAttrList[E_RANK_TYPE.ATTRACTIONS]"
            :key="item.attrId"
            class="flex cursor-pointer items-center px-4 py-2 hover:bg-[#F3F3F3]"
            @click="clickItem(({ itemData: item }), E_RANK_TYPE.ATTRACTIONS)"
          >
            <div h="11" w="11" flex="~ items-center justify-center">
              <img class="h-11 w-11" object="cover" :src="item.attrImg" rounded="xl" />
            </div>
            <div class="ml-2.5 flex flex-col justify-between gap-1 py-0.5">
              <div
                text="base #121212"
                font="500"
                v-html="item.highlightName"
              ></div>
              <div class="flex gap-1">
                <van-icon name="location" color="#aaa" />
                <span class="text-xs text-[#B2B2B2]">{{ item.address }}</span>
              </div>
            </div>
          </div>
          <div
            v-for="item in searchAttrList[E_RANK_TYPE.EXPERIENCE]"
            :key="item.specialTopicSerialNo"
            class="flex cursor-pointer items-center px-4 py-2 hover:bg-[#F3F3F3]"
            @click="clickItem(({ itemData: item }), E_RANK_TYPE.EXPERIENCE)"
          >
            <div h="11" w="11" flex="~ items-center justify-center">
              <img class="h-11 w-11" object="cover" :src="item.cover" rounded="xl" />
            </div>
            <div class="ml-2.5 flex flex-col justify-between gap-1 py-0.5">
              <div
                class="text-base text-[#121212] font-500"
                leading="tight"
                v-html="item.highlightName"
              ></div>
              <!-- <div class="flex gap-1">
                  <van-icon name="location" color="#aaa" />
                  <span class="text-xs text-[#B2B2B2]">{{ item.address }}</span>
                </div> -->
            </div>
          </div>
        </div>
        <div class="see-all-btn-container">
          <div class="see-all-btn" @click="onConfirm()">
            {{ t('key.search.destination.see.all.attr', [searchInput]) }}
          </div>
        </div>
      </template>
      <template v-else-if="searchLoading">
        <van-skeleton>
          <template #template>
            <div class="w-full">
              <div v-for="(_, index) in Array.from({ length: 6 })" :key="index" class="my-4 flex">
                <van-skeleton-title class="rounded-lg" style="height: 44px" title-width="44px" />
                <div class="flex-1">
                  <van-skeleton :row="2" />
                </div>
              </div>
            </div>
          </template>
        </van-skeleton>
      </template>
      <div v-else class="p-20 text-center text-base text-[#B2B2B2] font-500">
        {{ t('key.search.destination.emptyTips', [searchInput]) }}
      </div>
    </div>
    <div v-else class="p-10 text-center text-base text-[#B2B2B2] font-500">
      {{ t('key.search.attrs.placeholder') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ExpSearchDto, RankListAttrDto } from '~/apis/back/types'
import { E_RANK_TYPE } from '~/enums'

const emit = defineEmits<{
  (e: 'confirm'): void
}>()

const { navigatorToExperienceDetail } = useExperienceList()
const { navigatorToAttractionDetail } = useAttractionList()
const { resetExperienceFilter } = useExperienceList()
const { resetListFilter } = useFilterConditionsStore()

const {
  searchInput,
  history,
  searchAttrList,
  confirmInput,
  searchLoading,
  isSearching,
  onConfirmAttr,
  trackClickResultEvent,
} = useSearchAttr()

watch(confirmInput, (value) => {
  searchInput.value = value
})

const { t } = useI18n()

function onConfirm(rankType?: E_RANK_TYPE, item?: RankListAttrDto | ExpSearchDto) {
  emit('confirm')
  onConfirmAttr(rankType, item)
  resetListFilter()
  resetExperienceFilter()
}

function clickItem({ itemData }: { itemData: RankListAttrDto }, rankType: E_RANK_TYPE) {
  trackClickResultEvent()
  if (rankType === E_RANK_TYPE.EXPERIENCE) {
    navigatorToExperienceDetail({ itemData })
  }
  else {
    navigatorToAttractionDetail({ itemData })
  }
}
</script>

<style lang="scss" scoped>
.see-all-btn-container {
  width: 100%;
  padding: 10px 16px;
  .see-all-btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    border: 1px solid #000000;
    text-align: center;
    cursor: pointer;
    &:hover {
      border-color: var(--el-color-primary-light-7);
      color: var(--el-color-primary);
      background-color: var(--el-color-primary-light-9);
    }
    &:active {
      border-color: var(--el-color-primary);
      color: var(--el-color-primary);
      background-color: var(--el-color-primary-light-9);
    }
  }
}
</style>
